import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { baseurl } from "../../../baseSettings";
import {
  DatahubUserDetails,
  CurrentUserDetailsDTO as CurrentUserDetailsDTO,
} from "../../shared/models/datahubUser";
import { Language } from "../../shared/models/language";
import { UserIdsDTO } from "../../shared/models/userIdsDTO";
import { UserAuthService } from "../user-auth.service";

@Injectable()
export class UserService {
  private URL = `${this.api}/api/user`;
  public userDetails: UserIdsDTO;
  private userDetailsSubject: BehaviorSubject<UserIdsDTO>;
  constructor(
    private client: HttpClient,
    private userAuthService: UserAuthService,
    @Inject(baseurl) private api: string
  ) {
    this.userDetailsSubject = new BehaviorSubject<UserIdsDTO>(this.userDetails);
    this.userAuthService.notifyIsLoggedInChanged().subscribe((loggedIn) => {
      if (loggedIn){
        this.getIds().subscribe((userIds) => {
          this.userDetails = userIds;
          this.userDetailsSubject.next(this.userDetails);
        });
      } else {
        this.userDetails = null;
        this.userDetailsSubject.next(this.userDetails);
      }
    });
  }

  public notifyUserDetailsChanged(): Observable<UserIdsDTO> {
    return this.userDetailsSubject.asObservable();
  }

  public getRoles(): Observable<Array<string>> {
    return this.client.get<Array<string>>(`${this.URL}/role`);
  }

  public getPartner(): Observable<any> {
    return this.client.get<any>(`${this.URL}/partner`);
  }

  public getUserDetailsByUserId(
    userId: string
  ): Observable<DatahubUserDetails> {
    return this.client.get<DatahubUserDetails>(`${this.URL}/${userId}`);
  }

  public getCurrentUserDetails(): Observable<CurrentUserDetailsDTO> {
    return this.client.get<CurrentUserDetailsDTO>(`${this.URL}/current`);
  }

  public getId(): Observable<string> {
    return this.client.get<string>(`${this.URL}/exists`);
  }

  public updateCertificate(
    certificate: string,
    password: string
  ): Observable<any> {
    return this.client.post<any>(`${this.URL}/certificate`, {
      certificateBlob: certificate,
      password: password,
    });
  }

  public getLanguage(): Observable<Language> {
    return this.client.get<Language>(`${this.URL}/language`);
  }

  public updateLanguage(language: Language): Observable<any> {
    return this.client.put(`${this.URL}`, { language });
  }

  public partnerSignUp(email: string): Observable<any> {
    return this.client.post(`${this.URL}/partner-sign-up`, { email });
  }

  public farmerSignUp(email: string): Observable<any> {
    return this.client.post(`${this.URL}/farmer-sign-up`, { email });
  }

  public updateNotifications(userId: string): Observable<any> {
    return this.client.post(`${this.URL}/${userId}/notifications`, null);
  }

  public updateCurrentUserDetails(user: CurrentUserDetailsDTO) {
    return this.client.put(`${this.URL}/update-current/`, user);
  }

  public deleteOwnUserAccount(userId: string) {
    return this.client.delete(`${this.URL}/delete-own-account/${userId}`);
  }

  public deleteUserAccountAsAdmin(userId: string) {
    return this.client.delete(`${this.URL}/delete-account-as-admin/${userId}`);
  }

  public getIds(): Observable<UserIdsDTO> {
    return this.client.get<UserIdsDTO>(`${this.URL}/ids`);
  }
}
