import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.css']
})
export class TermsDialogComponent implements OnInit {

  hasAccepted: boolean;

  constructor(public dialogReg: MatDialogRef<TermsDialogComponent>) { }

  ngOnInit() {
  }

  onAccept() {
    this.dialogReg.close(this.hasAccepted);
  }

}
