import { Injectable, Inject } from '@angular/core';
import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Dataspace } from '../models/dataspace';

@Injectable()
export class DataspaceService {
  private URL = `${this.api}/api/dataspace`;

  dataspaces: Observable<Array<Dataspace>>;
  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public getAll(): Observable<Array<Dataspace>> {
    if (!this.dataspaces) {
      let response: Observable<any>;
      response = this.httpClient.get(this.URL);
      this.dataspaces = response.pipe(
        map(dataspaces => dataspaces.map(dataspace => Object.assign(new Dataspace(), dataspace)))
      );
    }
    return this.dataspaces;
  }
}
