import { Component, Input, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { UserRole } from "../../../shared/models/datahubUser";

@Component({
  selector: "app-context-start-page",
  templateUrl: "./context-start-page.component.html",
  styleUrls: ["./context-start-page.component.scss"],
})
export class ContextStartPageComponent implements OnChanges {
  @Input()
  role: UserRole;

  constructor(private router: Router) {}

  ngOnChanges() {
    if (this.isFarmer) {
      this.router.navigate([`manage-data-access`]);
    }
  }

  get isAdmin() {
    return this.role === UserRole.Admin;
  }
  get noRole() {
    return this.role === UserRole.Unknown;
  }
  get isFarmer() {
    return this.role === UserRole.Farmer;
  }
  get isProvider() {
    return this.role === UserRole.Provider;
  }
  get isConsumer() {
    return this.role === UserRole.Consumer;
  }
}
