import { Dataspace } from "../../core/models/dataspace";
import { DatahubUser } from "../../shared/models/datahubUser";
import { Language } from "../../shared/models/language";

export class CreateDatahubPartnerRequest {
  id: string;
  applicationUrl: string;
  companyName: string;
  applicationLogoUrl: string;
  role: string;
  certificate: any;
  password: string;
  inviteEmails: Array<string>;
  translations: Array<DatahubParterTranslation>;
  informativeFlow: boolean;
  externalDataspaceId: string;
  externalDataspaceKey: string; 
}

export class DatahubPartner {
  id: string;
  name: string;
  purpose: string;
  applicationUrl: string;
  companyName: string;
  applicationLogoUrl: string;
  role: DatahubPartnerRole;
  thumbprint: string;
  isActive: boolean;
  users: Array<DatahubUser>;
  translations: Array<DatahubParterTranslation>;
  informativeFlow: boolean;
  externalDataspace: Dataspace;
  externalDataspaceKey: string; 
}

export class DatahubParterTranslation {
  language: Language;
  name: string;
  purpose: string;
}

export enum DatahubPartnerRole {
  Unknown,
  Provider,
  Consumer,
}
